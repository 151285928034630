import "./css/index.scss";

import { IAudienceBasedSearchConfiguration } from "./Interfaces/IAudienceBasedSearchConfiguration";
import { IVisitorType } from "./Interfaces/IVisitorType";
import { IAction } from "./Interfaces/IAction";
import { Configuration } from "./Configuration/Configuration";
import * as Handlebars from "handlebars";
import axios from "axios";

export class AudienceBasedSearch {
    private AudienceBasedSearchButton: HTMLAnchorElement;
    private AudienceBasedSearchContainer: HTMLDivElement;
    private Configuration: IAudienceBasedSearchConfiguration;
    private SelectedAction: IAction;
    private SelectedVisitorType: IVisitorType;

    constructor(config: IAudienceBasedSearchConfiguration) {
        this.Configuration = config;
        this.SelectedVisitorType = this.Configuration.VisitorTypes[0];
        this.SelectedAction = this.SelectedVisitorType.Actions[0];
        this.AudienceBasedSearchContainer = <HTMLDivElement>document.getElementById("audience-based-search");
    }

    public async HandleVisitorTypeChange(selectedVisitorTypeVal: string) {
        this.SelectedVisitorType = this.Configuration.VisitorTypes.find(visitorType => {
            return visitorType.Val === selectedVisitorTypeVal;
        });

        document.getElementById("audience-based-search_action-select").innerHTML = "";

        this.SelectedVisitorType.Actions.forEach(element => {
            document.getElementById("audience-based-search_action-select").insertAdjacentHTML('beforeend', `<option value="${element.Val}">${element.Text}</option>`);
        });

        this.AudienceBasedSearchButton.href = this.SelectedVisitorType.Actions[0].Url;
        return false;
    };

    private async RenderVisitorTypesSelect() {
        let visitorTypeSelectTemplateResponse = await axios.get<string>(this.Configuration.VisitorTypeSelectTemplate);
        let visitorTypeSelectTemplate = Handlebars.compile(visitorTypeSelectTemplateResponse.data);
        const visitorTypes = visitorTypeSelectTemplate({
            VisitorTypes: this.Configuration.VisitorTypes,
            VisitorTypeSelectLabel: this.Configuration.VisitorTypeSelectLabel
        });

        this.AudienceBasedSearchContainer.insertAdjacentHTML('beforeend', visitorTypes);

        this.RenderActionsSelect();
    }

    public HandleActionChange(selectedActionVal: string) {
        this.SelectedAction = this.SelectedVisitorType.Actions.find(action => {
            return action.Val === selectedActionVal;
        });

        this.AudienceBasedSearchButton.href = this.SelectedAction.Url;

        return false;
    }

    private async RenderActionsSelect() {
        let actionSelectTemplateResponse = await axios.get<string>(this.Configuration.ActionSelectTemplate);
        let actionSelectTemplate = Handlebars.compile(actionSelectTemplateResponse.data);
        const actions = actionSelectTemplate({
            Actions: this.Configuration.VisitorTypes[0].Actions,
            ActionSelectLabel: this.Configuration.ActionSelectLabel
        });

        this.AudienceBasedSearchContainer.insertAdjacentHTML('beforeend', actions);

        this.RenderButton();
    }

    private async RenderButton() {
        let buttonTemplateResponse = await axios.get<string>(this.Configuration.ButtonTemplate);
        let buttonTemplate = Handlebars.compile(buttonTemplateResponse.data);
        const button = buttonTemplate({
            ButtonText: this.Configuration.ButtonText,
            Url: this.SelectedAction.Url
        });

        this.AudienceBasedSearchContainer.insertAdjacentHTML('beforeend', button);
        this.AudienceBasedSearchButton = <HTMLAnchorElement>document.getElementById("audience-based-search_button");

    }

    public static resetValues() {
        let selects: HTMLCollectionOf<HTMLSelectElement> = document.getElementById('audience-based-search').getElementsByTagName('select');
        Array.from(selects).forEach(select => select.selectedIndex = 0);
    }

    public Setup(): void {
        this.RenderVisitorTypesSelect();

    }
}

export function Initialize(userConfig: IAudienceBasedSearchConfiguration) {
    let config = new Configuration();
    Object.assign(config, userConfig);
    window.addEventListener('load', AudienceBasedSearch.resetValues);
    return new AudienceBasedSearch(config);
}
